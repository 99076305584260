import { IntroSection } from 'api/seo/types';
import { Arrow } from 'components/icons/Arrow';
import Link from 'next/link';

type Props = {
  data: IntroSection;
  keyword: string;
  windowWidth: number;
};

export const Intro = ({ data, keyword, windowWidth }: Props) => {
  return (
    <div
      className="px-8 py-4 md:py-12 rounded-xl"
      style={{
        backgroundImage: `url('/images/seo-background.png')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
    >
      <div className="max-w-[500px] flex flex-col gap-2">
        <ul className="flex gap-1 items-center text-sm text-stone-300">
          <li>
            <Link prefetch={false} href="/">
              Home
            </Link>
          </li>
          <Arrow className="w-4 h-4 -rotate-90" />
          <li>
            <Link prefetch={false} href="/categories">
              Categories
            </Link>
          </li>
          <Arrow className="w-4 h-4 -rotate-90" />
          <li className="cursor-pointer">{keyword}</li>
        </ul>
        <h1 className="text-2xl font-semibold">{data.title}</h1>
        <p className="text-stone-300 text-sm md:text-base">{data.content}</p>
        <Link prefetch={false} href="/" className="btn contained primary w-fit mt-3">
          Browse All Models
        </Link>
      </div>
    </div>
  );
};
