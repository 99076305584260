import classNames from 'classnames';
import { valuePropositions } from 'utils/hardcoded-data';

export const ValuePropositions = () => {
  return (
    <div className="">
      <div className="flex flex-col gap-20">
        {valuePropositions.map(({ title, subtext, img }, i) => {
          const isOdd = i % 2 === 0;

          const classes = classNames('overflow-hidden ml-auto mb-auto lg:flex order-0', {
            'md:order-1': isOdd,
            'md:order-3': !isOdd
          });
          return (
            <div key={title} className="grid md:grid-cols-2 grid-cols-1 gap-10">
              <div className={classes}>
                <img src={img.src} alt={title} className="object-contain rounded-lg" />
              </div>
              <div className="order-2">
                <h3 className="text-2xl font-medium text-brand mb-2">{title}</h3>
                <p className="text-md leading-7">{subtext}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
