import { BodySection } from 'api/seo/types';
import { slugify } from 'utils';

type Props = {
  data: BodySection;
  landingMapping: Record<string, string>;
  categoryMapping: Record<string, string>;
};

const renderTextWithLinks = (
  inputText: string,
  landingMapping: Record<string, string>,
  categoryMapping: Record<string, string>
) => {
  // Replace [[keyword-slug]] with link and readable Keyword
  const doubleBracketRegex = /\[\[(.*?)\]\]/g;
  let replacedText = inputText.replace(
    doubleBracketRegex,
    (_, keyword_slug) => {
      const categoryEntry = categoryMapping[keyword_slug];
      const landingEntry = landingMapping[keyword_slug];

      if (categoryEntry) {
        return `<a href="/categories/${keyword_slug}" class="text-brand hover:underline">${categoryEntry}</a>`;
      } else if (landingEntry) {
        return `<a href="/${keyword_slug}" class="text-brand hover:underline">${landingEntry}</a>`;
      } else {
        return keyword_slug; // Return the keyword without brackets if not found
      }
    }
  );

  // Replace [use-case] with link to homepage
  const singleBracketRegex = /(?<!\[)\[([^\[\]]+)\](?!\])/g;
  replacedText = replacedText.replace(
    singleBracketRegex,
    (_, temp) => `<a href="/" target="_blank" class="text-brand hover:underline" >${temp}</a>`
  );

  // Replace unmatchable **keyword**
  const starRegex = /\*\*([^*]+)\*\*/g;
  replacedText = replacedText.replace(starRegex, '$1');

  // Replace newlines
  replacedText = replacedText.replace(/\\n/g, '<br/>');

  return { __html: replacedText };
};

export const TextBody = ({ data, landingMapping, categoryMapping }: Props) => {
    return (
    <div>
      {data.paragraphs.map(({ title, body }) => (
        <div key={body} className="mb-10">
          <h3 className="text-2xl mb-2">{title}</h3>
          <div className="text-stone-300 leading-[30px]" dangerouslySetInnerHTML={renderTextWithLinks(body, landingMapping, categoryMapping)} />
        </div>
      ))}
    </div>
  );
};
