import { IconProps } from 'utils/global.types';

export const Present = (props?: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props} width="66.667" height="66.667" version="1" viewBox="0 0 50 50">
    <path
      fill="white"
      d="M216 465c7-28 2-30-27-15-24 14-26-5-1-19 12-8-6-10-70-11H30v-50c0-27 5-50 10-50 6 0 10-50 10-130V60h400v130c0 80 4 130 10 130s10 23 10 50v50h-87c-65 1-83 3-70 11 24 14 22 33-2 19-29-15-34-13-27 15 8 30-10 34-19 5-3-11-10-20-15-20s-12 9-15 20c-9 29-27 25-19-5zm24-95v-30H50v60h190v-30zm210 0v-30H260v60h190v-30zM240 200V80H70v240h170V200zm190 0V80H260v240h170V200z"
      transform="matrix(.1 0 0 -.1 0 50)"
    ></path>
  </svg>
);
