import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { SeoPage } from 'api/seo/types';
import { useModalsStore } from 'store/modals';
import useWindowWidth from 'hooks/useWindowWidth';
import { Present } from 'components/icons/Present';
import Link from 'next/link';
import { firstLetterUppercase, mapArrayToObject } from 'utils';

type Props = {
  bots: SeoPage['content']['bot_list'];
  landingMapping: Record<string, string>;
  categoryMapping: Record<string, string>;
};

export const BotsList = ({ bots, landingMapping, categoryMapping }: Props) => {
  const { push } = useRouter();
  const width = useWindowWidth();

  const { setIsOpenAuthModal } = useModalsStore();

  const botsNum = useMemo(() => (width >= 1024 ? 11 : 7), [width]);

  return (
    <div>
      <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
        {bots.slice(0, botsNum).map((bot) => {
          return (
            <div
              key={bot.url}
              className="relative border-2 border-primary rounded-xl overflow-hidden cursor-pointer trans hover:border-brand lg:hover:scale-105"
              onClick={() => push(bot.url)}
            >
              <img
                src={bot.image}
                alt={bot.name}
                className="w-full h-[250px] lg:h-[400px] object-cover object-top z-10"
              />

              <div className="absolute bottom-0 z-20 bg-gradient-to-t from-[#1f1f1f] to-transparent h-28 w-full" />
              <div className="absolute bottom-0 z-30 flex flex-col md:gap-1 p-3 w-full">
                <div className="flex flex-col gap-1 w-full">
                  <h2 className="text-xl font-medium">{bot.name}</h2>
                  <div className="flex gap-1">
                    {bot.tags.slice(0, 3).map((tag, i) => {
                      const tagLink = Object.keys(categoryMapping).find(key => categoryMapping[key] === bot.tags[i]); // Find the key by value

                      return tagLink ? (
                        <Link
                          prefetch={false}
                          href={`/categories/${tagLink}`}
                          key={tag}
                          target="_blank"
                          className="text-xs bg-[#1f1f1f]/50 hover:bg-brand/80 text-stone-300 px-3 py-2 rounded-full capitalize"
                          onClick={(e) => e.stopPropagation()}
                        >
                          {tag}
                        </Link>
                      ) : (
                        <span
                          key={tag}
                          className="text-xs bg-[#1f1f1f] text-stone-300 px-3 py-2 rounded-full capitalize"
                          onClick={(e) => e.stopPropagation()}
                        >
                          {tag}
                        </span>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        <div
          className="relative border-2 border-primary rounded-xl overflow-hidden cursor-pointer p-5 flex flex-col items-center justify-center gap-3"
          onClick={() => setIsOpenAuthModal(true)}
        >
          <Present />
          <div className="text-center">
            <h3 className="text-2xl uppercase">Sign Up</h3>
            <p className="uppercase">For Daily Free Credits</p>
          </div>

          <div className="text-center">
            <p className="text-stone-300">Get 50 credits for joining.</p>
            <p className="text-stone-300">The best AI models waiting just for you.</p>
          </div>

          <button type="button" className="btn primary contained" onClick={() => setIsOpenAuthModal(true)}>
            Sign Up
          </button>
        </div>
      </div>
    </div>
  );
};
